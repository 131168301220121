<template>
  <div class="page-form recharge">
    <el-card class="form-card">
      <el-form :model="form">
        <el-form-item label="" prop="qrcode">
          <div class="flex-center-start">
            <div class="qrcode" ref="qrcode" id="qrcode"></div>
            <div class="flex-column-center">
              <span>{{ $t('sao-miao-er-wei-ma') }}</span>
              <el-button>{{ $t('bao-cun-er-wei-ma') }}</el-button>
            </div>
          </div>
        </el-form-item>
        <el-form-item :label="$t('bi-zhong')" prop="coinType">
          <el-select v-model="form.address" @change="changeType">
            <el-option
              v-for="(item, i) in addressList"
              :key="i"
              :value="item.RechargeAddress"
              :label="item.RechargeNetwork"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('chong-zhi-di-zhi')" prop="address">
          <el-input
            v-model="form.address"
            :placeholder="$t('qing-shu-ru-chong-zhi-di-zhi-0')"
          >
            <template slot="suffix">
              <el-button class="copy-icon" @click="copyData" type="text">{{
                $t('fu-zhi')
              }}</el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('chong-zhi-shu-liang')" prop="number">
          <el-input
            v-model="form.number"
            clearable=""
            :placeholder="$t('qing-shu-ru-chong-zhi-shu-liang')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="
            $t('shang-chuan-tu-pian-shang-chuan-zhi-fu-xiang-qing-jie-tu')
          "
          prop="img"
        >
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            :action="uploadUrl"
            name="file"
            :headers="headers"
            :on-success="uploadSuccess"
            :before-upload="beforeUpload"
          >
            <img v-if="form.logo" :src="getBaseUrl(form.logo)" class="avatar" />
            <i
              v-else
              class="el-icon-camera-solid"
              style="font-size: 30px; color: #aaa"
            ></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="">
          <el-button class="submit-btn" @click="submit" type="primary">{{
            $t('ti-jiao')
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import QRCode from 'qrcodejs2'
import { getRechargeInfo, addRecharge } from '@/api/user'
import Clipboard from 'clipboard'
import { getToken } from '@/utils/auth'
import { getBaseUrl } from '@/utils/common'
export default {
  data() {
    return {
      form: {
        coinType: 'btc',
        address: '',
        number: '',
        logo: ''
      },
      typeList: [
        {
          key: 'btc',
          name: 'BTC',
          linkList: [
            {
              key: 'OMNI',
              name: 'OMNI'
            }
          ]
        },
        {
          key: 'eth',
          name: 'ETH',
          linkList: [
            {
              key: 'erc20',
              name: 'ERC20'
            }
          ]
        },
        {
          key: 'usdt',
          name: 'USDT',
          linkList: [
            {
              key: 'USDT-TRC20',
              name: 'TRC20'
            },
            {
              key: 'USDT-ERC20',
              name: 'ERC20'
            }
          ]
        }
      ],
      linkList: [],
      headers: {},
      addressList: []
    }
  },
  computed: {
    realMoney() {
      return this.form.number ? parseFloat(this.form.number).toFixed(2) : '0.00'
    },
    imgType() {
      return this.$store.state.imgType
    },
    uploadUrl() {
      return this.$store.state.uploadUrl
    }
  },
  mounted() {
    this.init()
    this.headers = {
      Token: getToken()
    }
  },
  methods: {
    getBaseUrl,
    init() {
      getRechargeInfo().then((res) => {
        this.addressList = res.data.Items

        let addressData = this.addressList[0]
        if (addressData) {
          this.form.address = addressData.RechargeAddress
          let qrcode = new QRCode(this.$refs.qrcode, {
            text: this.form.address,
            width: 120,
            height: 120,
            correctLevel: QRCode.CorrectLevel.H
          })
        }
      })
    },
    changeType(data, index) {
      this.resetQrcode()
    },
    resetQrcode() {
      let dom = document.getElementById('qrcode')
      if (dom) {
        dom.innerHTML = ''
      }
      let qrcode = new QRCode(this.$refs.qrcode, {
        text: this.form.address,
        width: 120,
        height: 120,
        correctLevel: QRCode.CorrectLevel.H
      })
    },
    changeLink(data) {
      this.form.network = data.key
      let addressData = this.addressList.find(
        (v) => v.RechargeNetwork == this.form.network
      )
      if (addressData) {
        this.form.address = addressData.RechargeAddress
        this.resetQrcode()
      }
      this.$forceUpdate()
    },
    beforeUpload(file) {
      let name = file.name
      let type = name.substring(name.indexOf('.') + 1)
      if (this.imgType.indexOf(type) === -1) {
        this.$message.error(this.$t('zhi-neng-shang-chuan-tu-pian'))
        return false
      }
      return true
    },
    uploadSuccess(file) {
      this.form.logo = file.data.FileName
    },
    submit() {
      addRecharge({
        Amount: parseFloat(this.form.number),
        PaymentVoucher: this.form.logo
      }).then((res) => {
        if (res.code == 0) {
          this.$message.success(this.$t('ti-jiao-cheng-gong'))
          this.form.logo = ''
          this.form.number = ''
          this.$refs.form.resetFields()
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    copyData() {
      let data = this.form.address
      const clipboard = new Clipboard('.copy-icon', { text: () => data })
      this.$message.success(this.$t('fu-zhi-cheng-gong'))
    },
    submit() {
      addRecharge({
        Amount: parseFloat(this.form.number),
        PaymentVoucher: this.form.logo
      }).then((res) => {
        if (res.code == 0) {
          this.$message.success(this.$t('ti-jiao-cheng-gong'))
          this.form.logo = ''
          this.form.number = ''
          this.$refs.form.resetFields()
        } else {
          this.$message.warning(res.msg)
        }
      })
    }
  }
}
</script>